import Swiper, { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

export default {
	init() {
		console.log('home');

		CustomEase.create('custom', '0.16, 1, 0.3, 1');

		jQuery(document).ready(function ($) {
			let $body = $('body');

			if ($('.loading_intro').length > 0) {
				gsap.to('.loading_intro', {
					duration: 0.3,
					delay: 5,
					opacity: 0,
					onComplete: function () {
						$body.css('overflow', 'auto');
						$('.loading_intro').remove();
					},
				});
			}

			const swiper = new Swiper('.swiper_standard', {
				modules: [EffectFade, Pagination, Navigation],
				effect: 'fade',
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.main_next',
					prevEl: '.main_prev',
				},
			});

			gsap.set('#heritageOverlay', {
				yPercent: 100,
				opacity: 1,
				pointerEvents: 'auto',
			});

			$('.js-open-heritage').bind('click', function (e) {
				e.preventDefault();
				$body.css('overflow', 'hidden');
				gsap.to('#heritageOverlay', {
					duration: 1.2,
					ease: 'custom',
					yPercent: 0,
				});
			});

			$('.js-close-heritage').bind('click', function (e) {
				e.preventDefault();
				gsap.to('#heritageOverlay', {
					duration: 1.2,
					ease: 'custom',
					yPercent: 100,
					onComplete: function () {
						$body.css('overflow', 'auto');
					},
				});
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};

import Swiper, { Navigation } from 'swiper';
import 'swiper/css';

export default {
	init() {
		jQuery(document).ready(function ($) {
			const swiper = new Swiper('.swiper_reviews', {
				modules: [Navigation],
				spaceBetween: 20,
				slidesPerView: 'auto',
				breakpoints: {
					744: {
						slidesPerView: 1,
						spaceBetween: 5,
					},
					1024: {
						slidesPerView: 1,
						spaceBetween: 10,
					},
				},
				navigation: {
					nextEl: '.next_btn',
					prevEl: '.prev_btn',
				},
			});
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};

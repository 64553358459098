const DEV = process.env.NODE_ENV === 'development';

import Swiper, { EffectFade, Thumbs, Navigation } from 'swiper';
import 'swiper/css';

//import imagesLoaded from 'imagesloaded';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		//Dark mode storage
		let isDarkMode = localStorage.getItem('theme') || '';
		if (isDarkMode) document.body.classList.add(isDarkMode);

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		function hideLoader() {
			document.body.classList.add('loaded');
		}

		jQuery(document).ready(function ($) {
			setTimeout(() => {
				hideLoader();
			}, 1000);

			//Accordion
			const accordions = document.querySelectorAll(
				'.js-toggle-accordion'
			);
			if (accordions) {
				accordions.forEach((item, i) => {
					item.addEventListener('click', function (e) {
						this.classList.toggle('open');
						var tgId = this.dataset.target;
						if (tgId) {
							var panel = document.getElementById(tgId);
							if (panel.style.maxHeight) {
								panel.style.maxHeight = null;
							} else {
								panel.style.maxHeight =
									panel.scrollHeight + 'px';
							}
						}
					});
				});
				$('.js-toggle-accordion').first().trigger('click');
			}

			$('.slider_block').each(function () {
				var $sliderBlock = $(this);
				var idBlock = $sliderBlock.attr('id');

				const hSwiperThumbs = new Swiper('#thumbs' + idBlock, {
					slidesPerView: 'auto',
					slideToClickedSlide: true,
				});
				const hSwiper = new Swiper('#swiper' + idBlock, {
					modules: [EffectFade, Thumbs, Navigation],
					effect: 'fade',
					allowTouchMove: false,
					navigation: {
						nextEl: '#next' + idBlock,
						prevEl: '#prev' + idBlock,
					},
					thumbs: {
						swiper: hSwiperThumbs,
					},
					on: {
						afterInit: (swiper) => {
							swiper.thumbs.swiper.params.slideToClickedSlide = true;
							swiper.thumbs.swiper.update();
						},
						realIndexChange: (swiper) => {
							swiper.thumbs.swiper.slideToLoop(swiper.realIndex);
						},
					},
				});
			});
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};

import Router from './js/util/Router';
import common from './js/routes/common';
import home from './js/routes/home';
import singleApartment from './js/routes/singleApartment';

import './scss/application.scss';
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** About Us page, note the change from about-us to aboutUs. */
	singleApartment,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
